"use client";

import { createClient } from "@/utils/supabase/client";
import { incrementClick } from "@/utils/turso/actions/creator";
import { incrementPostClick } from "@/utils/turso/actions/post";
import { Box } from "@mui/material";

/** used to wrap around link clicks to handle click tracking */
const ClickWrapper = ({
  id,
  fieldName,
  type,
  children,
}: {
  id: string;
  /** field to increment */
  fieldName: string;
  type: "creator" | "post";
  children: React.ReactNode;
}) => {
  /** update downloads count for album */
  const supabase = createClient();

  const updateCreator = async () => {
    await supabase.rpc("increment_creator", {
      x: 1,
      creator_param: id,
      field_name: fieldName,
    });

    await incrementClick(id);
  };

  const updatePost = async () => {
    await supabase.rpc("increment", {
      x: 1,
      post_id_param: id,
      field_name: fieldName,
    });

    await incrementPostClick(id);
  };

  return (
    <Box
      onClick={async () => {
        type === "creator" ? updateCreator() : updatePost();
      }}
    >
      {children}
    </Box>
  );
};

export default ClickWrapper;
